@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.wh-100 {
  width: 100%;
  height: 100%;
}
.fit_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#ticketPriceBar {
  /* left: 67%;
  right: 7.96%; */
  position: sticky;
  position: -webkit-sticky;
  top: 4rem;
  opacity: 1;
  transition: 0.3s;
}
.ios-link-container {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: translateY(100);
  opacity: 1;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  visibility: inherit;
}
.swiper-wrapper {
  justify-content: center;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .swiper-wrapper {
    justify-content: flex-start;
  }
  /* #ticketPriceBar {
    right: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    margin-top: 29px;
    background: rgb(249 250 251 / var(--tw-bg-opacity));
    padding: 10px 30px;
  } */
}
